import React from "react"
import { Link } from 'gatsby';
import Layout from '../../components/Layout';
//import { useSiteMetadata } from "../hooks/use-site-metadata"


const ListingPage = ({ pageContext: { publicHighlightsForTag, tag } }) => (<Layout>
  <div>
    <div class="flex flex-wrap justify-center">
          I came across {publicHighlightsForTag.length} nuggets about the topic : {tag} on internet.
    </div>
    <div class="flex flex-wrap justify-center">
    You can search these links on my public listing page on &nbsp;<a href={"https://alpha.app.learningpaths.io/#/highlights/public/5bb5bb5fa3664d73e323afdf?search=&intag:" + tag}>learning paths</a>
    </div>
    {/* <div><pre>{JSON.stringify(publicHighlights, null, 2)}</pre></div> */}

    <div className="flex flex-wrap">
      {publicHighlightsForTag.map(publicHighlight => {
        let image_src = "https://image.thum.io/get/crop/1000/auth/3227-uniqueString/" + publicHighlight._source.url;
        //console.log(publicHighlight);
        return (
          <div key={publicHighlight._id} className="my-1 px-1/2 w-full md:w-1/2 lg:my-4 lg:px-4 lg:w-1/3 card overflow-y-auto max-h-[32rem]">
            <div className="max-w-xs rounded overflow-hidden shadow-lg my-2" key={publicHighlight._id}>
              <a href={publicHighlight._source.url} target="_blank" rel="noreferrer">
                <img className="w-full" src={image_src} alt="Website Screenshot" />
              </a>
              <div className="px-6 py-4">
                <div className="font-bold text-xl mb-2">{publicHighlight._source.title}</div>
                {/* <span>publicHighlight._source.selected_text</span> */}
                <p className="text-grey-darker text-base">
                  {publicHighlight._source.selected_text ? publicHighlight._source.selected_text : publicHighlight._source.added_note}
                </p>
              </div>
              <div className="px-2 py-2">
                {publicHighlight._source.tags_details.map(tag => {
                  const tagLink = "/curious-about/" + tag.name;
                  return (<a key={tag._id} href={tagLink}><span className="inline-block bg-grey-lighter rounded-full px-3 py-1 text-sm font-semibold text-grey-darker mr-2">#{tag.name}</span></a>)
                })}
              </div>
            </div>
          </div>
        )
      })}
    </div>

  </div>

  <Link to="/">Home</Link>
</Layout >
);

export default ListingPage;

